import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HashEllipsisPipe } from "./hash-ellipsis.pipe";
import { ReplacePipe } from "./replace.pipe";

@NgModule({
	declarations: [HashEllipsisPipe, ReplacePipe],
	imports: [CommonModule],
	exports: [HashEllipsisPipe, ReplacePipe],
})
export class PipesModule {}

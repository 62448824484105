export const environment = {
	production: false,
	env: "dev",
	supabaseUrl: "https://yfsptfsjagampaslymri.supabase.co",
	supabaseKey:
		"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inlmc3B0ZnNqYWdhbXBhc2x5bXJpIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODExNDM3MDUsImV4cCI6MTk5NjcxOTcwNX0.wrCdLrDQBOXjpGg1PyHzlyK8aMIJma_SPFYez4d5yHE",
	// lambda3: "https://rh2bsmk3kbyz7qi4qfd3imthwy0qztan.lambda-url.us-west-1.on.aws",
	lambda3: "https://nkkh3ni6f5.execute-api.us-west-1.amazonaws.com/dev/backend",
	sentryDsn: "https://aa44dcaad24f4e2392b3eb9c15d36071@o4504777439051776.ingest.sentry.io/4504777453666304",
	cognitoIdentityPoolId: "us-west-1:39bab5cd-d012-4814-b060-9a52f8ba718a",
	pinpointAppId: "f1b037e246e64043a258129504265e83",
	walletconnectProjectId: "aa29c65b15465be862e3a5325489a945",
	contractAddress: "0xCc9599DE2C9eD7b690aC9242d3A77F358D3316AC",
	chainId: "0x5",
	crossmintClientId: "8e2ea3be-5d1a-4484-8c02-d59434ad0e00",
	lambdaApiKey: "abcdefg",
};
import "zone.js/plugins/zone-error";

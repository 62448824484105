<!-- <button class="push-button--flat arcade-button-component" [ngClass]="calculateStyles(size)"></button> -->
<div class="arcade-button-component-wrapper">
	<div class="arcade-button-component">
		<!-- If Button is normal -->
		<div
			class="arcade-link"
			*ngIf="!url"
			[style.flexDirection]="
				nameInverted ? 'column-reverse' : horizontal ? 'row' : 'column'
			"
		>
			<button
				class="push-button--skeuo arcade-button-component no-link"
				id="button"
				#button
				*ngIf="!url"
				[disabled]="disabled || loading"
				(click)="buttonPressed()"
			></button>
			<span
				class="arcade-name arcade"
				*ngIf="!url && name"
				[style.color]="textColor"
				[style.marginLeft]="horizontal ? '10px' : '0px'"
				>{{ loading ? "..." : name }}</span
			>
			<span
				class="arcade-description"
				*ngIf="!url && description"
				[style.color]="textColor"
				>{{ description }}</span
			>
		</div>

		<!-- If Button == Link -- Refactor Me -->
		<a
			class="arcade-link"
			*ngIf="url"
			[routerLink]="url"
			[style.flexDirection]="nameInverted ? 'column-reverse' : 'column'"
			type="button"
		>
			<button
				class="push-button--skeuo arcade-button-component"
				id="button"
				#button
				[disabled]="disabled || loading"
				(click)="buttonPressed()"
			></button>
			<span class="arcade-name arcade" *ngIf="name" [style.color]="textColor">{{
				name
			}}</span>
			<span
				class="arcade-description"
				*ngIf="description"
				[style.color]="textColor"
				>{{ description }}</span
			>
		</a>
	</div>
</div>

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class KnobStateService {
	private currentAngle = new BehaviorSubject<number>(0);
	currentAngle$ = this.currentAngle.asObservable();

	public setAngle(angle: number) {
		this.currentAngle.next(angle);
	}
}

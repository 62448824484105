<mat-grid-list
	[cols]="cols"
	(window:resize)="onResize($event)"
	rowHeight="{{ rowHeight }}"
>
	<mat-grid-tile
		class="arcade-tile"
		*ngFor="let tile of consoleTiles"
		[colspan]="tile.cols"
		[rowspan]="tile.rows"
		[style.background]="tile.color"
		[style.color]="tile?.textColor"
	>
		<span *ngIf="tile.text">{{ tile.text }}</span>
		<arcade
			class="arcade-console-button"
			id="arcade{{ tile.id }}"
			*ngIf="tile.name"
			[name]="tile.name"
			[textColor]="tile?.textColor"
			[size]="tile?.size"
			[nameInverted]="tile?.nameInverted"
			[id]="tile?.id"
			[url]="tile.url"
			[description]="tile.description"
			[image]="tile?.image"
			[size]="tile.size"
			[color]="tile?.color"
			[disabled]="tile?.disabled"
			(click)="event(tile.click)"
		></arcade>
		<div class="joystick" #joystick *ngIf="tile.joystick"></div> </mat-grid-tile
></mat-grid-list>

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
	selector: "arcade-button",
	templateUrl: "./arcade-button.component.html",
	styleUrls: ["./arcade-button.component.scss"],
})
export class ArcadeButtonComponent implements OnInit, AfterViewInit {
	/**
	 * Name of the button
	 */
	@Input() name?: string;

	/**
	 * Button in a disabeld status.
	 */
	@Input() disabled?: boolean;

	/**
	 * Size of the button. Always a 1:1 ration. Default 35px
	 */
	@Input() size = 35;

	/**
	 * Color of the button.
	 */
	@Input() color: string;

	/**
	 * Event that is dispatched when the button has been pressed
	 */
	@Output() pressed = new EventEmitter<boolean>();

	/**
	 * Element Reference
	 */
	@ViewChild("button") button!: ElementRef;

	enabled = true;
	constructor() {}

	ngAfterViewInit(): void {
		if (this.disabled) {
			this.enabled = !this.disabled;
		}
	}

	ngOnInit(): void {}

	public buttonPressed(_event) {
		this.pressed.emit(true);
	}
}

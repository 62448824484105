import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { getRandomNumberInRange } from "@shared/util/helpers";

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: "arcade",
	templateUrl: "./arcade.component.html",
	styleUrls: ["./arcade.component.scss"],
})
export class ArcadeComponent implements OnInit, AfterViewInit {
	/**
	 * Is this the principal call to action on the page?
	 */

	@Input() name?: string;
	/**
	 * Fake Button Alert
	 */

	@Input() fake?: boolean;
	/**
	 * Button in a disabeld status.
	 */

	@Input() active?: boolean;
	/**
	 * Button in a disabeld status.
	 */
	@Input() disabled?: boolean;

	/**
	 * Element Reference
	 */
	@ViewChild("button") button!: ElementRef;

	/**
	 * Event that is dispatched when the button has been pressed
	 */
	@Output() pressed = new EventEmitter<boolean>();

	/**
	 * Not in use
	 */
	@Input() type = "";
	enabled = true;

	/**
	 * Size of the button. Always a 1:1 ration. Default 60px
	 */
	@Input() size: "15px" | "35px" | "50px" | "60px" | "90px" = "60px";

	// Url handline
	@Input() url?: string;
	@Input() inverseUrl?: string;
	ogUrl!: string;

	@Input() image?: string;
	@Input() description!: string;
	@Input() color: "pink" | "random" | "white" | "lightblue" | "default" = "default";
	@Input() textColor?: string;
	@Input() nameInverted?: boolean;
	@Input() horizontal?: boolean = false;

	@Input() loading?: boolean;

	constructor() {}

	ngAfterViewInit(): void {
		if (this.disabled) {
			this.enabled = !this.disabled;
		}

		if (this.image && this.button) {
			this.addImage(this.image);
		}

		if (this.color && this.button) {
			this.changeColor(this.color);
		}

		if (this.size && this.button) {
			//
			this.changeSize(this.size);
		}

		if (window.innerWidth < 200) {
			this.changeSize("35px");
		}

		if (this.url && this.inverseUrl) {
			this.ogUrl = this.url;
		}

		if (this.fake) {
			this.changeColor("random");
		}

		if (this.active) {
			this.changeColor("invert");
		}

		if (this.horizontal) {
			//
		}
	}

	ngOnInit(): void {}

	private addImage(image: any) {
		// this.button.style.setProperty("background-image", `url(${this.image})`);
		this.button.nativeElement.style.setProperty("background-size", "25px");
		this.button.nativeElement.style.setProperty("background-position", "center center");
		this.button.nativeElement.style.setProperty("background-repeat", "no-repeat");
	}

	private changeSize(size: string) {
		this.button.nativeElement.style.setProperty("height", size);
		this.button.nativeElement.style.setProperty("width", size);
	}
	public buttonPressed() {
		this.pressed.emit(true);
		this.changeColor("white");
		if (this.inverseUrl) {
			// this.toggleUrl();
		}
	}

	private toggleUrl() {
		if (this.url == this.ogUrl) {
			this.url = this.inverseUrl;
		} else {
			this.url = this.ogUrl;
		}
	}

	public changeColor(color: any) {
		let r = 45;
		if (color === "random") {
			r = getRandomNumberInRange(0, 360);
			const filter = "hue-rotate(" + r + "deg)";
			this.button.nativeElement.style.setProperty("filter", filter);
		}

		if (color === "pink") {
			const filter = "hue-rotate(" + r + "deg)";
			this.button.nativeElement.style.setProperty("filter", filter);
		}

		if (color === "invert") {
			const filter = "invert(.8)";
			this.button.nativeElement.style.setProperty("filter", filter);
		}
	}
}

import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
	selector: "arcade-tile",
	templateUrl: "./arcade-tile.component.html",
	styleUrls: ["./arcade-tile.component.scss"],
})
export class ArcadeTileComponent implements OnInit {
	@ViewChild("content", { static: true }) content: ElementRef<HTMLDivElement>;
	@Input() title?: string = "";
	@Input() action?: string;
	@Input() verticalContent?: boolean = false;
	@Input() centerTitle?: boolean = false;
	@Input() centerContent?: boolean = false;
	@Input() hideFooter?: boolean = false;
	@Input() transparent?: boolean = false;
	@Input() optimize?: boolean = false;
	@Input() scrollSnap?: boolean = false;
	@Output() tileMessage = new EventEmitter<string>();
	@Input() glowBorder?: boolean = false;
	@Input() heightAuto?: boolean = false;
	@Input() blur?: boolean = false;

	cDarkGrey = "#232323";

	tileStyle?: any = {};

	contentStyle: any = {
		flexDirection: "row",
		transition: "initial",
	};
	footerStyle = {
		textAlign: "left",
		justifyContent: "flex-start",
	};

	@HostListener("scroll", ["$event"])
	onScroll(event: Event) {
		if (!this.scrollSnap) return;
		const div = event.target as HTMLDivElement;
		const scrollTop = div.scrollTop;
		// const scrollHeight = div.scrollHeight;
		const snapHeight = 45; // height of each snap point
		const snappedScrollTop = Math.round(scrollTop / snapHeight) * snapHeight;

		if (scrollTop !== snappedScrollTop && scrollTop <= 90) {
			div.scroll({
				top: snappedScrollTop,
				behavior: "smooth",
			});
		}
	}

	constructor() {}

	ngOnInit(): void {
		if (this.verticalContent) {
			this.contentStyle.flexDirection = "column";
		}

		if (this.centerTitle === true) {
			this.footerStyle.textAlign = "center";
		}

		if (this.centerContent === true) {
			this.contentStyle.justifyContent = "center";
		}

		if (this.hideFooter === true) {
			this.contentStyle.height = "auto";
		}

		if (this.transparent === true) {
			this.tileStyle.background = "transparent";

			setTimeout(() => {
				// this.tileStyle.borderColor = "transparent";
			}, 5000);
		}

		if (this.scrollSnap) {
			this.content.nativeElement.addEventListener("scroll", this.onScroll.bind(this));
		}

		if (this.heightAuto) {
			this.tileStyle.height = "auto";
			this.tileStyle.width = "auto";
			this.tileStyle.minHeight = "auto";
			this.tileStyle.minWidth = "auto";
			this.contentStyle.minHeight = "6px";
		}
	}

	actionPressed(_event) {
		const message = _event.target.ariaLabel;

		this.tileMessage.emit(message);
		this.toggleActionButton();
	}

	toggleActionButton() {
		if (this.action === "mute") {
			this.action = "mute_2";
		} else if (this.action === "mute_2") {
			this.action = "mute";
		}
	}
}

import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";

@Component({
	selector: "arcade-display",
	templateUrl: "./arcade-display.component.html",
	styleUrls: ["./arcade-display.component.scss"],
	animations: [trigger("collapse", [state("open", style({ opacity: 1 })), state("closed", style({ opacity: 0 })), transition("open <=> closed", [animate("100ms ease-in-out")])])],
})
export class ArcadeDisplayComponent implements OnChanges {
	@ViewChild("displayText") textRef!: ElementRef;
	@Input() title = "Track1.mp3"; // The title text to be displayed in the arcade display
	@Input() image: string; // The URL of the image to be displayed on the right side of the arcade display when `contentRight` is set to true
	@Input() contentRight = false; // When set to true, the image will be displayed on the right side of the arcade display
	@Input() fontSize = 14; // The font size of the title text
	@Input() color = "#00e785"; // The color of the title text and the inner glow
	@Input() animated = false; // When set to true, the arcade display will have an animated effect
	@Input() innerGlow = false; // When set to true, the arcade display will have an inner glow effect
	@Input() lines = 1; // The number of lines of text to be displayed in the arcade display
	@Input() dinSize: string; // The size of the DIN (can be "i", "ii", or "iii")
	@Input() inverted = false; // When set to true, the background color and text color will be inverted
	@Input() alignment?: string; // The text alignment of the title text (e.g., "left", "center", "right")
	@Input() valignment?: string; // The vertical alignment of the title text (e.g., "top", "center", "bottom")

	wrapperStyle: any;
	titleStyle: any;

	constructor() {
		this.wrapperStyle = {
			boxShadow: "inset 0px 0px 10px " + this.color,
			textAlign: this.alignment || "center",
		};

		// Determine vertical alignment
		let alignItems = "flex-start"; // Default alignment
		if (this.valignment === "center") {
			alignItems = "center";
		} else if (this.valignment === "bottom") {
			alignItems = "flex-end";
		}

		this.titleStyle = {
			display: "flex",
			flexDirection: "row",
			justifyContent: "flex-start",
			alignItems: alignItems,
			width: "100%",
			height: "100%",
			fontSize: this.fontSize + "px",
			color: this.color,
		};
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes["valignment"]) {
			// Update vertical alignment based on the new value
			let alignItems = "flex-start"; // Default alignment
			if (this.valignment === "center") {
				alignItems = "center";
			} else if (this.valignment === "bottom") {
				alignItems = "flex-end";
			}

			// Update titleStyle with the new alignment
			this.titleStyle = {
				...this.titleStyle,
				alignItems: alignItems,
			};
		}
		this.wrapperStyle = {
			...this.wrapperStyle,
			boxShadow: "inset 0px 0px 10px " + this.color,
			textAlign: this.alignment,
		};
		this.titleStyle = {
			...this.titleStyle,
			fontSize: this.fontSize + "px",
			color: this.color,
		};
		if (this.contentRight) {
			this.alignment = "left";
			this.titleStyle = {
				...this.titleStyle,
			};
			this.wrapperStyle = {
				...this.wrapperStyle,
			};
		}

		// if (this.inverted) {
		// 	this.wrapperStyle = {
		// 		...this.wrapperStyle,
		// 		backgroundColor: this.color,
		// 		color: "#000",
		// 	};
		// 	this.titleStyle = {
		// 		...this.titleStyle,
		// 		color: "#000",
		// 		padding:
		// 	};
		// }

		if (this.hasOverflow()) {
			this.textRef.nativeElement.classList.add("scrolling");
		}
	}

	hasOverflow(): boolean {
		if (this.textRef) {
			//
			//
			//
			// return true
		}

		return false;
	}
}

import { Injectable, inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "@env/environment";
import { SupabaseClient, createClient } from "@supabase/supabase-js";

import { Contract, ethers } from "ethers";

import { abi } from "./contract";
const contractABI = abi;
const contractAddress = environment.contractAddress as string;

declare const window: any;

@Injectable()
export class SupabaseService {
	protected supabase: SupabaseClient;
	protected snackbar: MatSnackBar;

	private apiBase = environment.supabaseUrl;
	private signer: ethers.Signer;

	apiAlive = false;
	retries = 0;

	constructor() {
		this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey);
		this.snackbar = inject(MatSnackBar);
		// this.arcadeService = inject(ArcadeService);
		// this.supabase.auth.onAuthStateChange((event, session) => {});
	}

	isAuthenticated(): boolean {
		return this.supabase.auth.getSession() !== null;
	}

	async heartbeat() {
		try {
			const url = new URL(environment.lambda3 + `/y2k`);
			const response = await fetch(url.href, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": environment.lambdaApiKey,
				},
			});
			const json = await response.json();

			this.apiAlive = true;
			return json;
		} catch (error) {
			console.error("Error calling Lambda function:", error);
			return null;
		}
	}

	async publicRandomMint() {
		try {
			// const gasLimit =
			// 2233100 = .04 ETH
			// 90109;

			const provider = new ethers.providers.Web3Provider(window.ethereum as any, "any");
			const network = await provider.getNetwork();
			const networkChainId = network.chainId.toString();
			this.signer = await provider.getSigner();
			const _contract = new Contract(contractAddress, contractABI, this.signer);

			if (networkChainId !== environment.chainId.split("0x")[1]) {
				throw new Error("Wrong_network_connect_to_" + environment.chainId);
			}

			const to = await this.signer.getAddress();
			const quantity = 1;

			// Estimate the gas needed to mint
			const gasLimit = await _contract.estimateGas.mint(to, quantity, {
				value: ethers.utils.parseEther("0.1"),
			});

			const mintTx = await _contract.mint(to, quantity, { gasLimit: gasLimit, value: ethers.utils.parseEther("0.1") });

			// Wait for the transaction to be confirmed
			const receipt = await mintTx.wait();

			return receipt.transactionHash;
		} catch (error) {
			console.error("Error minting NFT:", error);
			return null;
		}
	}

	async mintSpecific(_tokenId: string) {
		try {
			const provider = new ethers.providers.Web3Provider(window.ethereum as any, "any");
			const network = await provider.getNetwork();
			const networkChainId = network.chainId.toString();
			this.signer = await provider.getSigner();
			const _contract = new Contract(contractAddress, contractABI, this.signer);

			if (networkChainId !== environment.chainId.split("0x")[1]) {
				throw new Error("Wrong_network_connect_to_" + environment.chainId);
			}

			const to = await this.signer.getAddress();
			const quantity = 1;

			// build a post request to the lambda function
			const payload = {
				tokenId: _tokenId,
			};
			const url = new URL(environment.lambda3 + `/y2k/evm/mint`);
			const response = await fetch(url.href, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": environment.lambdaApiKey,
				},
				body: JSON.stringify(payload),
			});
			const json = await response.json();

			// Estimate the gas needed to mint
			const gasLimit = await _contract.estimateGas.mint(to, quantity, {
				value: ethers.utils.parseEther("0.1"),
			});

			const isMinting = true;

			// const mintTx = await _contract.mint(to, quantity, { gasLimit: gasLimit, value: ethers.utils.parseEther("0.1") });

			// Wait for the transaction to be confirmed
			// const receipt = await mintTx.wait();

			//

			// return receipt.transactionHash;
			return;
		} catch (error) {
			console.error("Error minting NFT:", error);
			return null;
		}
	}

	async connectToMetamask(): Promise<{ signer: ethers.Signer; chain: string; account: string }> {
		try {
			// if (typeof window.ethereum === "undefined") {
			// 	throw Error("Metamask not Home");
			// }

			const provider = new ethers.providers.Web3Provider(window.ethereum as any, "any");
			const network = await provider.getNetwork();
			const networkChainId = network.chainId.toString();

			// if (networkChainId !== environment.chainId.split("0x")[1]) {
			// 	throw Error("Wrong_network_connect_to_" + environment.chainId);
			// }

			const [accounts, chainId] = await Promise.all([provider.send("eth_requestAccounts", []), provider.send("eth_chainId", [])]);

			this.signer = await provider.getSigner();
			const signer = this.signer;

			return { signer, chain: chainId, account: accounts[0] };
		} catch (error: any) {
			throw Error(error);
		}
	}

	async connectToWalletConnect() {
		// Initialize WalletConnect client
		// const signClient = await SignClient.init({
		// 	projectId: environment.walletconnect_project_id,
		// 	// Optional parameters
		// 	relayUrl: "",
		// 	metadata: {
		// 		name: "Example Dapp",
		// 		description: "Example Dapp",
		// 		url: "#",
		// 		icons: ["https://walletconnect.com/walletconnect-logo.png"],
		// 	},
		// });
		// // Initialize Web3Modal
		// const web3Modal = new Web3Modal({
		// 	projectId: environment.walletconnect_project_id,
		// 	standaloneChains: ["eip155:1"],
		// 	walletConnectVersion: 2,
		// });
		// Connect to WalletConnect and get the session
		// Follow the WalletConnect v2 SDK documentation
		// Extract the necessary information from the session (account, chain, etc.)
		// Create an ethers.Signer using the connected wallet's account and chain
		// Return the signer, chain, and account
	}

	async requestMessage(_account, _chain): Promise<any> {
		const params = {
			action: "request-message",
			address: _account,
			chain: _chain,
			networkType: "evm",
		};

		const result = await this.callSupabaseFunction(params);

		return result;
	}

	async verifyMessage(_message, _signature, _chain): Promise<any> {
		const params = {
			action: "sign-message",
			signature: _signature,
			message: _message,
			networkType: "evm",
		};

		const result = await this.callSupabaseFunction(params);

		const jwt = result.user.token as string;

		//
		//

		const _user = await this.supabase.auth.getUser(jwt);
		if (_user) {
			const session = result.user.session;

			await this.supabase.auth.setSession(session);
			const _session = await this.supabase.auth.refreshSession(session);
		}

		return result;
	}

	async getInventory(): Promise<any> {
		try {
			const url = new URL(environment.lambda3 + `/y2k/evm/eth/inventory`);
			const response = await fetch(url.href, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (!response.ok) {
				const errorText = await response.text();
				throw new Error(`Error calling Lambda function: ${response.status} - ${errorText}`);
			}

			return response.json();
		} catch (error) {
			console.error("Error calling Lambda function:", error);
			return null;
		}
	}

	async getFishFromInventory(): Promise<any> {
		try {
			const inventory = await this.getInventory();
			const getRandomfishFromArray = (array: any[]) => array[Math.floor(Math.random() * array.length)];
			const url = new URL(environment.lambda3 + `/y2k/stream/${getRandomfishFromArray(inventory)}`);
			const response = await fetch(url.href);

			return response.json();
		} catch (error) {
			console.error("Error calling Lambda function:", error);
			return null;
		}
	}

	async getTokens(): Promise<any> {
		try {
			const url = new URL(environment.lambda3 + `/y2k/evm/eth/tokens/cache`);
			let response: any = await fetch(url.href);
			response = await response.json();
			return response.message;
		} catch (error) {
			console.error("Error calling Lambda function:", error);
			const tokens = [];
			const count = 0;
			const response = { tokens, count };
			return response;
		}
	}

	// Get Fish in S3 by Id
	async fetch(_name: string | number) {
		const url = new URL(environment.lambda3 + `/y2k/stream/${_name}`);
		const response = await fetch(url.href);
		return response.json();
	}

	async callSupabaseFunction(params: any) {
		try {
			const url = new URL(environment.lambda3 + `/${params.action}`);
			const response = await fetch(url.href, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(params),
			});

			if (!response.ok) {
				const errorText = await response.text();
				throw new Error(`Error calling Supabase function: ${response.status} - ${errorText}`);
			}

			const data = await response.json();

			return data;
		} catch (error) {
			console.error("Error calling Supabase function:", error);
			return null;
		}
	}

	// Default Get Cache
	async getStreamOfFish(_chain: string): Promise<any> {
		try {
			const alive = await this.heartbeat();
			if (!alive && this.retries < 3) {
				setTimeout(() => {
					this.retries++;
					this.getStreamOfFish(_chain);
					return;
				}, 3000);
			}
			const url = new URL(environment.lambda3 + `/y2k/stream/cache`);
			const hostname = window.location.hostname;
			const response = await fetch(url.href, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (!response.ok) {
				const errorText = await response.text();
				throw new Error(`Error calling Lambda function: ${response.status} - ${errorText}`);
			}

			const data = await response.json();

			return data.metadata.metadata;
		} catch (error) {
			console.error("Error calling Lambda function:", error);
			return null;
		}
	}

	// Replace the getSettings() function
	async getSettings(): Promise<any> {
		const { data: user } = await this.supabase.auth.getSession();
		if (!user) {
			console.error("Error getting settings: User not logged in");
			return null;
		}

		const { data, error } = await this.supabase.from("userSettings").select("*").eq("user", user);

		if (error) {
			console.error("Error getting settings:", error);
			return null;
		}

		return data;
	}

	// Get Default Settings
	async getDefaultSettings(_exhibit: string): Promise<any> {
		try {
			const filepath = `assets/exhibits/${_exhibit}Settings.json`;
			const response = await fetch(filepath);
			if (!response.ok) {
				throw new Error("File not found");
			}

			const data = await response.json();
			return data;
		} catch (error) {
			console.error("There was a problem with the fetch operation:", error);
		}
	}

	// Get Fish
	async getFish(): Promise<any> {
		const { data, error } = await this.supabase.from("fish").select("*");

		if (error) {
			console.error("Error getting fish:", error);
			return null;
		}

		return data;
	}

	// Get Fish with batch number
	async getFishFromFarm(batchNumber: number): Promise<any> {
		const { data, error } = await this.supabase.from("fish").select("*").eq("batch", batchNumber);

		if (error) {
			console.error("Error getting fish:", error);
			return null;
		}

		return data;
	}

	async signUp(email: string, password: string): Promise<void> {
		const { error } = await this.supabase.auth.signUp({ email, password });
		if (error) {
			this.showErrorSnackbar(error.message);
			throw error;
		}
	}

	async signIn(email: string, password: string): Promise<void> {
		const { error } = await this.supabase.auth.signInWithPassword({ email, password });
		if (error) {
			this.showErrorSnackbar(error.message);
			throw error;
		}
	}

	async signOut(): Promise<void> {
		const { error } = await this.supabase.auth.signOut();
		if (error) {
			this.showErrorSnackbar(error.message);
			throw error;
		}
	}

	async updateUserProfile(data: any): Promise<void> {
		const { data: user } = await this.supabase.auth.getSession();
		if (!user) {
			this.showErrorSnackbar("User not logged in");
			throw new Error("User not logged in");
		}

		// const { error } = await this.supabase.from("userSettings").update(data).match({ user: user });
		// if (error) {
		// 	this.showErrorSnackbar(error.message);
		// 	throw error;
		// }
	}

	async saveSettings(settings: Map<string, any>): Promise<any> {
		try {
			const { data: session } = await this.supabase.auth.getSession();

			if (!session) {
				console.error("Error saving settings: User not logged in");
				throw new Error("User not logged in");
			}

			const payload = Object.fromEntries(settings);

			const { data, error } = await this.supabase.from("userSettings").insert(payload);

			if (error) {
				console.error("Error saving settings", error);
				throw new Error(error as any);
			}

			this.snackbar.open("Track " + payload.track_id + " Settings Saved");
			return;
		} catch (error) {
			this.snackbar.open("Error Saving Settings");
			throw new Error("Error Saving Settings");
		}
	}

	// get fish by id
	async getFishById(id): Promise<any> {
		const { data, error } = await this.supabase.from("fish").select("*").eq("id", id);

		if (error) {
			console.error("Error getting fish:", error);
			return null;
		}

		return data;
	}

	private showErrorSnackbar(message: string): void {
		this.snackbar.open(message, "", { duration: 3000, panelClass: "error-snackbar" });
	}
}

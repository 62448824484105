<!-- <button class="push-button--flat arcade-button-component" [ngClass]="calculateStyles(size)"></button> -->
<div class="arcade-button-component-wrapper">
	<div class="arcade-button-component">
		<div class="arcade-link">
			<div class="a-button-base-ring-1">
				<div class="a-button-base-ring-2">
					<div class="a-button-base-ring-3">
						<div class="a-button-inner-1">
							<div class="a-button-inner-2">
								<div class="a-button-inner-3">
									<button
										class="push-button"
										id="button"
										#button
										[disabled]="disabled"
										(click)="buttonPressed($event)"
										matButton
									></button>
									<div class="a-button-overlay"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<span class="arcade-name" *ngIf="name">{{ name }}</span>
		</div>
	</div>
</div>

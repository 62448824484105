import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import Variables from "@style/scss-variables";

@Component({
	selector: "arcade-switch",
	templateUrl: "./switch.component.html",
	styleUrls: ["./switch.component.scss"],
})
export class ArcadeSwitchComponent implements OnInit, OnChanges {
	@Output() pressed = new EventEmitter<boolean>();
	indicators = {
		off: { color: Variables.lightGrey, tip: "Off" },
		disabled: { color: Variables.darkGrey, tip: "Disabled" },
		on: { color: Variables.cSeaGreen, tip: "On" },
		error: { color: Variables.cBrightRed, tip: "Error" },
		loading: { color: Variables.lightPurple, tip: "Loading" },
	};
	@Input() on = false;
	@Input() loading?: boolean = false;
	@Input() error?: boolean = false;
	@Input() disabled = false;
	@Input() title = "Fun";
	@Input() color!: string;
	@Input() indicatorColor: string = this.indicators.off.color;
	@Input() indicatorOnTip?: string;
	@Input() indicatorOffTip?: string;
	indicatorTip: string;
	indicatorBlink: boolean;

	disabledStyle = {
		// background: "slategrey",
	};

	constructor() {}

	ngOnInit(): void {
		this.toggleIndicator();
	}

	ngOnChanges() {
		this.toggleIndicator();
	}

	buttonPress(_event) {
		// this.on = !this.on;
		this.toggleIndicator();
		this.pressed.emit(this.on);
	}

	toggleIndicator() {
		if (this.disabled) {
			this.indicatorColor = this.indicators.disabled.color;
			this.indicatorTip = this.indicators.disabled.tip;
			this.indicatorBlink = false;
		} else if (this.on && this.color) {
			this.indicatorColor = this.color;
			this.indicatorTip = this.indicatorOnTip || this.indicators.on.tip;
			this.indicatorBlink = false;
		} else if (this.on) {
			this.indicatorColor = this.indicators.on.color;
			this.indicatorTip = this.indicatorOnTip || this.indicators.on.tip;
			this.indicatorBlink = false;
		} else if (!this.on && this.error) {
			this.indicatorColor = this.indicators.error.color;
			this.indicatorTip = this.indicators.error.tip;
			this.indicatorBlink = false;
		} else if (!this.on && this.loading) {
			this.indicatorColor = this.color || this.indicators.on.color;
			this.indicatorTip = this.indicators.loading.tip;
			this.indicatorBlink = true;
		} else if (!this.on) {
			this.indicatorColor = this.indicators.off.color;
			this.indicatorTip = this.indicatorOffTip || this.indicators.off.tip;
			this.indicatorBlink = false;
		}
	}
}

import { Injectable } from "@angular/core";
import { Connector } from "@app/_shared/util/types";

import { ArcadeService } from "@app/_shared/arcade.service";

import { SupabaseService } from "@app/_shared/supabase.service";
import { environment } from "@env/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { HashEllipsisPipe } from "../../pipes/hash-ellipsis.pipe";

declare const window: any;

@Injectable({
	providedIn: "root",
})
export class AuthService {
	redirectUrl?: string;
	user?: any;
	private currentUserSubject: BehaviorSubject<any>;
	public currentUser: Observable<any>;
	loginRequest: Promise<any> | null = null;
	ethereum: any;
	connector: Connector | null = null;
	injector: any;

	constructor(
		private hashPipe: HashEllipsisPipe,
		private supabase: SupabaseService,
		private arcadeService: ArcadeService,
	) {
		console.log("AuthService constructor");
		// this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem("currentUser")!));
		// this.currentUser = this.currentUserSubject.asObservable();
	}

	isLoggedIn() {
		return this.supabase.isAuthenticated();
	}

	isMember() {
		if (this.currentUserSubject.value.tokens.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	public async getRole(): Promise<string> {
		return this.currentUserSubject.value.role;
	}

	public get currentUserValue(): any {
		return this.currentUserSubject.value;
	}

	setCurrentUser(user: any) {
		this.currentUserSubject.next(user);
		localStorage.removeItem("currentUser");
	}

	setCurrentAccount(account: any) {
		// this.provider
		localStorage.removeItem("currentUser");
		localStorage.removeItem("token");
		this.currentUserSubject.next(null);
	}

	async login(provider: "metamask" | "walletconnect" = "metamask"): Promise<any> {
		try {
			// Analytics.record({ name: "login", attributes: { state: "start", provider: provider } });
			// Connect to Metamask
			let loginMessage = `><((((^> dialing_metamask`;
			this.arcadeService.pagerEvent().next(loginMessage);
			const { signer, chain, account } = await this.supabase.connectToMetamask();

			if (!account) {
				throw new Error("No_account_found");
			}

			if (!chain) {
				throw new Error("No_chain_found");
			}

			const { message } = await this.supabase.requestMessage(account, chain);
			const signature = await signer.signMessage(message.message);
			loginMessage = `><((((^> faxing_metaquarium`;
			await this.arcadeService.pagerEvent().next(loginMessage);
			const { user } = await this.supabase.verifyMessage(message.message, signature, chain);
			loginMessage = `checking_fax_machine <"))))><`;
			await this.arcadeService.pagerEvent().next(loginMessage);
			const _user = user.user;
			const token = user.token;

			const hashAddress = this.hashPipe.transform(_user.moralis_provider_id);

			loginMessage = `><((((^> welcome ${hashAddress} 
				of chain ${chain}`;
			if (_user.ens) {
				loginMessage = `><((((^> welcome ${_user.ens} 
					of chain ${chain}`;
			}

			this.arcadeService.pagerEvent().next(loginMessage);

			if (_user && _user.tokens && _user.tokens.length > 0) {
				if (_user.tokens.length > 8) {
					_user.tokens.slice(0, 8);
				}

				const memberMessage = `><((((^>   welcome_member   <"))))><`;
				this.arcadeService.pagerEvent().next(memberMessage);

				// welcomeMessage = `welcome ${_user.moralis_provider_id} of chain ethereum with ${_user.tokens.length} tokens`;
				await Promise.all(
					_user.tokens.map(async (token: any) => {
						if (token.token_uri) {
							const response = await fetch(token.token_uri);
							const metadata = await response.json();
							token.metadata_s3 = metadata;
						}
					}),
				);

				const memberMessage2 =
					`><((((^> detected ` +
					_user.tokens.length +
					` 
				metaverse fishies <"))))><`;
				this.arcadeService.pagerEvent().next(memberMessage2);
				this.arcadeService.playerEvent().next("member-login");
			}

			this.currentUserSubject.next(_user);

			localStorage.setItem("currentUser", JSON.stringify(_user));
			localStorage.setItem("token", JSON.stringify(token));

			return _user;
		} catch (error: any) {
			// remove duplicate error messages

			const errorMessage = `><((((x> ${error}`;
			this.arcadeService.pagerEvent().next(errorMessage);
			throw new Error(error);
		}
	}

	logout(): Promise<boolean> {
		if (!this.isLoggedIn()) {
			return Promise.resolve(false);
		}

		return this.supabase
			.signOut()
			.then((user) => {
				this.arcadeService.pagerEvent().next("><((((^> disconnecting");
				console.info("logout", user);
				// Analytics.record({ name: "logout", attributes: { state: "success" } });
			})
			.then(() => {
				// this.setLoggedInUser(undefined);
				localStorage.removeItem("currentUser");
				localStorage.removeItem("token");
				this.currentUserSubject.next(null);
				this.arcadeService.playerEvent().next("logout");
			})
			.then(() => {
				this.arcadeService.pagerEvent().next("><((((^> disconnected");
				if (window.ethereum) {
					// this.arcadeService.pagerEvent().next("");
				}

				return true;
			})
			.catch((e) => {
				localStorage.removeItem("currentUser");
				localStorage.removeItem("token");
				console.error("logout error:", e);
				const errorMessage = `error logging out: ${e.message}`;
				this.arcadeService.pagerEvent().next("><((((x> " + errorMessage);
				return false;
			});
	}

	getConnector(): Connector | null {
		// Check if window.ethereum is present
		if (window.ethereum) {
			// If present, return connector object with account and chainId
			this.connector = {
				account: window.ethereum.selectedAddress || null,
				chainId: window.ethereum.chainId || null,
				isMetaMask: window.ethereum.isMetaMask || false,
			};
			return this.connector;
			// Check if window.web3 is present
		} else if (window.web3) {
			// If present, return connector object with account and chainId
			this.connector = {
				account: window.web3.eth.accounts[0] || null,
				chainId: window.web3.eth.chainId || null,
				isMetaMask: true,
			};
			return this.connector;
		} else {
			return null;
		}
	}

	async listenWeb3Events() {
		window.addEventListener("ethereum#initialized", () => this.initMetaMask(), {
			once: true,
		});

		setTimeout(() => this.initMetaMask(), 3000);
	}

	async initMetaMask() {
		console.info("initMetaMask");
		if (window.ethereum) {
			// Metamask Provider ---------
			this.ethereum = window.ethereum;

			this.ethereum.on("accountsChanged", async (accounts) => {
				const hashAddress = this.hashPipe.transform(accounts[0]);
				this.arcadeService.pagerEvent().next("Good Morning " + hashAddress);

				if (accounts.length === 0) {
					// User is logged out from MetaMask; log them out from your app as well
					this.logout();
				} else {
					// Update your app's state with the new user account
					this.setCurrentAccount(accounts[0]);
				}
			});

			this.ethereum.on("chainChanged", async (chainId) => {
				this.arcadeService.playerEvent().next("chain-changed");
				this.arcadeService.pagerEvent().next("chain_changed " + chainId);
				// // Check if the new network is supported
				if (chainId !== environment.chainId) {
					this.arcadeService.pagerEvent().next("please_visit " + environment.chainId);
				} else {
					this.arcadeService.pagerEvent().next("connecting_to  " + environment.chainId);
				}
			});

			this.ethereum.on("disconnect", (error) => {
				this.arcadeService.playerEvent().next("chain-disconnect");
				// this.snackbar.open("disconnect " + error);
				// Emitted when the currently connected chain is invalidated or any other chain events occur that
				// invalidate the session.
				// this.logout();
			});

			const connector = {
				account: this.ethereum.selectedAddress,
				chainId: this.ethereum.chainId,
				isMetaMask: this.ethereum.isMetaMask,
				isBraveWallet: this.ethereum.isBraveWallet || false,
			};
			return connector;
		} else {
			return null;
		}
	}

	initLedger() {
		// 	  try {
		//   // Keep if you chose the USB protocol
		//   const transport = await TransportWebUSB.create();
		//   // Keep if you chose the HID protocol
		//   const transport = await TransportWebHID.create();
		//   //listen to the events which are sent by the Ledger packages in order to debug the app
		//   listen(log =>
		//   //When the Ledger device connected it is trying to display the bitcoin address
		//   const appBtc = new AppBtc(transport);
		//   const { bitcoinAddress } = await appBtc.getWalletPublicKey(
		//     "44'/0'/0'/0/0",
		//     { verify: false, format: "legacy"}
		//   );
		//   //Display your bitcoin address on the screen
		//   const h2 = document.createElement("h2");
		//   h2.textContent = bitcoinAddress;
		//   $main.innerHTML = "<h1>Your first Bitcoin address:</h1>";
		//   $main.appendChild(h2);
		//   //Display the address on the Ledger device and ask to verify the address
		//   await appBtc.getWalletPublicKey("44'/0'/0'/0/0", {format:"legacy", verify: true});
		// } catch (e) {
		//   //Catch any error thrown and displays it on the screen
		//   const $err = document.createElement("code");
		//   $err.style.color = "#f66";
		//   $err.textContent = String(e.message || e);
		//   $main.appendChild($err);
		// }
	}
}

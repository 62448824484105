export default {
	cPurple: "#6a00ff",
	cTerminalGreen: "lime",
	cSeaGreen: "#00e785",
	cSlate: "#2a2d32",
	cSilver: "#bcbcbc",
	cGrey: "#868688",
	cOrange: "#fba600",
	cYellow: "#dbff00",
	cWhite: "#fff",
	cBlack: "#000",
	cDarkGrey: "#232323",
	cSeaBlue: "#0d565a",
	cRed: "#ff4847",
	cBrightRed: "#e33735",
	cSeaweedGreen: "#203031",
	aNoise: 'url("../../assets/images/console-noise.png")',
	breakpointWatch: "200px",
	breakpointMobile: "415px",
	breakpointMobileMax: "500px",
	breakpointTablet: "768px",
	breakpointDesktop: "1024px",
	breakpointHd: "1920px",
	breakpoint4k: "3840px",
	breakpoint5k: "5120px",
	breakpoint8k: "7680px",
	tileMargin: "3px",
	tileMarginAll: "24",
	navHeight: "100px",
	consoleHeight: "250px",
	consoleHeight4k: "300px",
	toolbarHeight: "64px",
	black: "#000",
	darkGrey: "#555",
	lightGrey: "#999",
	white: "#fff",
	darkRed: "#a00",
	lightRed: "#fee",
	darkGreen: "#0a0",
	lightGreen: "#efe",
	darkBlue: "#00a",
	lightBlue: "#eef",
	darkYellow: "#a50",
	lightYellow: "#ffe",
	darkPurple: "#a0a",
	lightPurple: "#fef",
	darkCyan: "#0aa",
	lightCyan: "#eff",
	synthwave1: "#6cf",
	synthwave2: "#fcf",
	synthwave3: "#ffc",
	synthwave4: "#cff",
	synthwave5: "#ccf",
	synthwave6: "#fcc",
	synthwave7: "#cfc",
	synthwave8: "#fc9",
	synthwave9: "#9cf",
	synthwave10: "#c9f",
};

export const colors = {
	synthwave1: "#6cf",
	synthwave2: "#fcf",
	synthwave3: "#ffc",
	synthwave4: "#cff",
	synthwave5: "#ccf",
	synthwave6: "#fcc",
	synthwave7: "#cfc",
	synthwave8: "#fc9",
	synthwave9: "#9cf",
	synthwave10: "#c9f",
};

export const tropicalColors = {
	tropical1: "#FFA07A",
	tropical2: "#20B2AA",
	tropical3: "#5F9EA0",
	tropical4: "#8B4513",
	tropical5: "#FF6347",
	tropical6: "#00CED1",
	tropical7: "#1E90FF",
	tropical8: "#FFD700",
	tropical9: "#FF4500",
	tropical10: "#2E8B57",
};

export const miamiViceColors = {
	miamivice1: "#ff6ad5", // Hot Pink
	miamivice2: "#c774e8", // Light Purple
	miamivice3: "#ad8cff", // Lavender
	miamivice4: "#8795e8", // Periwinkle Blue
	miamivice5: "#94d0ff", // Light Sky Blue
	miamivice6: "#4fb4f4", // Azure
	miamivice7: "#00a6fb", // Bright Blue
	miamivice8: "#0085a1", // Cerulean
	miamivice9: "#0b3d91", // Yale Blue
	miamivice10: "#1c1c1c", // Off Black
};

export const bloomColors = {
	bloomColor1: "#FF69B4", // Hot Pink
	bloomColor2: "#FFFF00", // Yellow
	bloomColor3: "#00FFFF", // Cyan
	bloomColor4: "#00FF00", // Lime
	bloomColor5: "#FF4500", // OrangeRed
	bloomColor6: "#7FFF00", // Chartreuse
};

export function getTropicalColor(): string {
	const colorKeys = Object.keys(colors);
	const randomKey = colorKeys[Math.floor(Math.random() * colorKeys.length)];
	return colors[randomKey];
}

export function getSynthwaveColor(): string {
	const colorKeys = Object.keys(colors);
	const randomKey = colorKeys[Math.floor(Math.random() * colorKeys.length)];
	return colors[randomKey];
}

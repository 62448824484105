<div class="switch-wrapper">
	<div class="switch-indicator">
		<arcade-indicator
			[power]="on"
			[color]="indicatorColor"
			[shadowColor]="indicatorColor"
			[blink]="indicatorBlink"
			[size]="10"
			[tooltip]="indicatorTip"
			[tooltipPosition]="'above'"
		></arcade-indicator>
	</div>
	<div class="switch-button">
		<div class="switch-button-l2 switch-base">
			<div class="switch-button-l3 switch-base-noise">
				<div
					class="switch-button-l4 switch-top"
					[style]="disabled ? disabledStyle : ''"
				>
					<div class="switch-button-l5 switch-top-noise">
						<button
							[disabled]="disabled || loading"
							[attr.aria-label]="title"
							(click)="buttonPress($event)"
							type="button"
						></button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="switch-label">
		{{ title }}
	</div>
</div>

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
	{
		path: "gallery",
		loadChildren: () => import("./gallery/gallery.module").then((m) => m.GalleryModule),
		// canActivate: [DeveloperGuard],
	},

	// {
	// 	path: "designer",
	// 	loadChildren: () => import("./designer/designer.module").then((m) => m.DesignerModule),
	// 	canActivate: [DeveloperGuard],
	// },

	// {
	// 	path: "mint",
	// 	loadChildren: () => import("./mint/mint.module").then((m) => m.MintModule),
	// },
	// {
	// 	path: "directory",
	// 	redirectTo: "gallery/directory",
	// },
	// {
	// 	path: "breeds",
	// 	redirectTo: "gallery/breeds",
	// },
	// {
	// 	path: "humans",
	// 	redirectTo: "gallery/humans",
	// },
	// {
	// 	path: "invite",
	// 	redirectTo: "gallery/invite",
	// },
	{
		path: "token/:id",
		loadChildren: () => import("./token/token.module").then((m) => m.TokenModule),
		data: {
			network: "ethereum",
		},
	},
	{
		path: "preview/:id",
		loadChildren: () => import("./token/token.module").then((m) => m.TokenModule),
		data: {
			network: "preview",
		},
	},
	// {
	// 	path: "tank",
	// 	loadChildren: () => import("./scene/scene.module").then((m) => m.SceneModule),
	// },
	// {
	// 	path: "shop",
	// 	loadChildren: () => import("./shop/shop.module").then((m) => m.ShopModule),
	// },

	// This module looks for :id so load it last
	{
		path: "",
		loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
	},
	{
		path: "**",
		loadChildren: () => import("./four-o-four/four-o-four.module").then((m) => m.FourOFourModule),
	},
];

// canActivate: [ProtectedGuard],

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}

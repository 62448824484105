import { Pipe, PipeTransform } from "@angular/core";
import { getEllipsisTxt } from "@shared/util/helpers";

@Pipe({
	name: "hashPipe",
})
export class HashEllipsisPipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		const hash = getEllipsisTxt(value);
		return hash;
	}
}

// import Variables from "@style/scss-variables";

export const albumS3Url = "https://s3.us-west-2.amazonaws.com/metaquarium.art/albums/ethereum/";

export const album = {
	name: "Ethereum",
	tracks: [
		{
			id: 1,
			title: "00_ζo))))彡.wav",
			breed: "betafish",
			slug: "beta-fish-3",
			bin: true,
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
						},
					},
					breeds: [
						{ shark: 9 },
						{ seahorse: 5 },
						{ crab: 5 },
						{ betafish: 0 },
						{ dori: 1 },
						{ seaturtle: 5 },
						{ glowfish: 20 },
						{ angelfish: 10 },
						{ babyfish: 3 },
						{ blowfish: 7 },
						{ hackerfish: 10 },
					],
				},
			},
			mp3: albumS3Url + "web/8.mp3",
			webm: albumS3Url + "web/8.webm",
			flac: albumS3Url + "hifi/8.flac",
			howl: null,
			schoolSize: 3,
			schools: 5,
			guiParams: null,
			rotation: {
				x: 0.0,
				y: -Math.PI / 3,
				z: 0.0,
			},
			animate: {
				oscilate: {
					x: 0.05,
					y: 0.05,
					z: 0.05,
				},
				movement: {
					x: 0.1,
					y: 0.0,
					z: 0,
				},
			},
		},
		{
			id: 2,
			title: "01_ʚʘ͡))彡.wav",
			breed: "seahorse",
			slug: "sea-horse5",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: Math.PI / 4,
							z: 0,
						},
						curve: "spiral",
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
						},
					},
					breeds: [
						{ shark: 4 },
						{ seahorse: 30 },
						{ crab: 15 },
						{ betafish: 0 },
						{ dori: 1 },
						{ seaturtle: 0 },
						{ glowfish: 30 },
						{ angelfish: 15 },
						{ babyfish: 1 },
						{ blowfish: 2 },
						{ hackerfish: 10 },
					],
				},
			},
			mp3: albumS3Url + "web/4.mp3",
			webm: albumS3Url + "web/4.webm",
			flac: albumS3Url + "hifi/4.flac",
			howl: null,
			schoolSize: 4,
			schools: 5,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.05,
					y: 0.05,
					z: 0.05,
				},
				movement: {
					x: 0.01,
					y: 0.0,
					z: 0.0,
				},
			},
		},
		{
			id: 3,
			title: "02_(/)!_!(/).wav",
			breed: "glowfish",
			slug: "glow-fish3",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
						},
					},
					breeds: [
						{ shark: 2 },
						{ seahorse: 2 },
						{ crab: 10 },
						{ betafish: 0 },
						{ dori: 14 },
						{ seaturtle: 0 },
						{ glowfish: 30 },
						{ angelfish: 25 },
						{ babyfish: 1 },
						{ blowfish: 31 },
						{ hackerfish: 10 },
					],
				},
			},
			mp3: albumS3Url + "web/5.mp3",
			webm: albumS3Url + "web/5.webm",
			flac: albumS3Url + "hifi/5.flac",
			howl: null,
			schoolSize: 4,
			schools: 8,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.25,
					y: 0.15,
					z: 0.25,
				},
				movement: {
					x: 0.05,
					y: 0.05,
					z: 0.05,
				},
			},
		},
		{
			id: 4,
			breed: "sea-turtle",
			title: "03_ε( 'Θ' )϶.wav",
			slug: "sea-turtle2",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
							showWireframe: true,
						},
					},
					breeds: [
						{ shark: 7 },
						{ seahorse: 20 },
						{ crab: 0 },
						{ betafish: 0 },
						{ dori: 20 },
						{ seaturtle: 40 },
						{ glowfish: 20 },
						{ angelfish: 5 },
						{ babyfish: 9 },
						{ blowfish: 0 },
						{ hackerfish: 10 },
					],
				},
			},
			mp3: albumS3Url + "web/6.mp3",
			webm: albumS3Url + "web/6.webm",
			flac: albumS3Url + "hifi/6.flac",
			howl: null,
			schoolSize: 4,
			schools: 9,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.15,
					y: 0.15,
					z: 0.15,
				},
				movement: {
					x: 0.01,
					y: 0,
					z: 0.0,
				},
			},
		},
		{
			id: 5,
			breed: "babyfish",
			title: "04⋙l;((((*≿.wav",
			slug: "baby-fish2",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
							showWireframe: true,
						},
					},
					breeds: [
						{ shark: 1 },
						{ seahorse: 1 },
						{ crab: 5 },
						{ betafish: 0 },
						{ dori: 12 },
						{ seaturtle: 8 },
						{ glowfish: 30 },
						{ angelfish: 5 },
						{ babyfish: 10 },
						{ blowfish: 13 },
						{ hackerfish: 10 },
					],
				},
			},
			mp3: albumS3Url + "web/7.mp3",
			webm: albumS3Url + "web/7.webm",
			flac: albumS3Url + "hifi/7.flac",
			howl: null,
			schoolSize: 10,
			schools: 8,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.05,
					y: 0.05,
					z: 0.05,
				},
				movement: {
					x: 0.01,
					y: 0,
					z: 0.0,
				},
			},
		},
		{
			id: 6,
			breed: "angelfish",
			title: "05_くコ∷彡.wav",
			slug: "angel-fish3",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
							showWireframe: true,
						},
					},
					breeds: [
						{ shark: 1 },
						{ seahorse: 0 },
						{ crab: 35 },
						{ betafish: 0 },
						{ dori: 25 },
						{ seaturtle: 0 },
						{ glowfish: 40 },
						{ angelfish: 25 },
						{ babyfish: 1 },
						{ blowfish: 0 },
						{ hackerfish: 0 },
					],
				},
			},
			mp3: albumS3Url + "web/2.mp3",
			webm: albumS3Url + "web/2.webm",
			flac: albumS3Url + "hifi/2.flac",
			howl: null,
			schoolSize: 4,
			schools: 8,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.01,
					y: 0.01,
					z: 0.01,
				},
				movement: {
					x: 0.01,
					y: 0,
					z: 0.0,
				},
			},
		},
		{
			id: 7,
			title: "06_≼Θ)))))⋊.wav",
			breed: "hackerfish",
			slug: "hacker-fish3",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
							showWireframe: true,
						},
					},
					breeds: [
						{ shark: 8 },
						{ seahorse: 15 },
						{ crab: 5 },
						{ betafish: 0 },
						{ dori: 1 },
						{ seaturtle: 10 },
						{ glowfish: 10 },
						{ angelfish: 3 },
						{ babyfish: 10 },
						{ blowfish: 30 },
						{ hackerfish: 20 },
					],
				},
			},
			mp3: albumS3Url + "web/1.mp3",
			webm: albumS3Url + "web/1.webm",
			flac: albumS3Url + "hifi/1.flac",
			howl: null,
			schoolSize: 4,
			schools: 8,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.09,
					y: 0.05,
					z: 0.07,
				},
				movement: {
					x: 0.01,
					y: 0.01,
					z: 0.01,
				},
			},
		},
		{
			id: 8,
			title: "07_{;☄` ̧ ̧. ́ ̄`·.wav",
			breed: "crab",
			slug: "crab3",
			scene: {
				init: {
					camera: {
						position: {
							x: 0,
							y: 0,
							z: 0,
						},
						rotation: {
							x: 0,
							y: 0,
							z: 0,
						},
					},
					water: {
						rotation: {
							x: 0.0,
							y: 0.0,
							z: 0.0,
						},
						material: {
							color: {
								r: 0.0,
								g: 0.0,
								b: 0.0,
							},
							showWireframe: true,
						},
					},
					breeds: [
						{ shark: 4 },
						{ seahorse: 2 },
						{ crab: 45 },
						{ betafish: 0 },
						{ dori: 12 },
						{ seaturtle: 0 },
						{ glowfish: 10 },
						{ angelfish: 5 },
						{ babyfish: 50 },
						{ blowfish: 30 },
						{ hackerfish: 3 },
					],
				},
			},
			mp3: albumS3Url + "web/3.mp3",
			webm: albumS3Url + "web/3.webm",
			flac: albumS3Url + "hifi/3.flac",
			howl: null,
			schoolSize: 4,
			schools: 8,
			guiParams: null,
			animate: {
				oscilate: {
					x: 0.05,
					y: 0.05,
					z: 0,
				},
				movement: {
					x: 0.01,
					y: 0,
					z: 0.0,
				},
			},
			gravity: true,
		},
	],
};

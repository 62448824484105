import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "arcade-type",
	templateUrl: "arcade-type.component.html",
})
export class ArcadeTypeComponent implements OnInit {
	@Input() text: string;
	@Input() type: string;

	constructor() {}

	ngOnInit(): void {
		return;
	}
}

import { Injectable } from "@angular/core";
declare const performance: any;

@Injectable({
	providedIn: "root",
})
export class CacheService {
	private readonly caches: Map<string, Cache>;
	private size = 0;
	private timer: any;
	/**
	 * Interval ID of the memory logging interval.
	 */
	private memoryLogIntervalId: number;

	constructor() {
		setInterval(() => {
			this.logMemoryUsage();
		}, 10000);
		
	}

	private logMemoryUsage(): void {
		// if ("PerformanceObserver" in window) {
		// 	

		// 	const memoryObserver = new PerformanceObserver((list) => {
		// 		
		// 		const entries = list.getEntries();
		// 		for (const entry of entries) {
		// 			
		// 			// 
		// 		}
		// 	});
		// 	memoryObserver.observe({ entryTypes: ["memory"] });
		// 	// 
		// }

		if (performance && performance.memory) {
			// const memory = performance.memory;
			// 
			// 
			// 
			// 
			// 
			// 
		}
	}

	set(key: string, value: any, ttl = 0): void {
		this.caches.set(key, value);
		this.size += JSON.stringify(value).length;
		if (ttl > 0) {
			setTimeout(() => {
				this.delete(key);
			}, ttl);
		}
	}

	get(key: string): any {
		return this.caches.get(key);
	}

	delete(key: string): boolean {
		const value = this.caches.get(key);
		if (value) {
			this.caches.delete(key);
			this.size -= JSON.stringify(value).length;
			return true;
		} else {
			return false;
		}
	}

	clear(): void {
		this.caches.clear();
		this.size = 0;
	}

	ngOnDestroy() {
		clearInterval(this.timer);
	}
}

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { getIPFSUrl } from "../util/helpers";
export interface LogoLocation {
	id: number;
	active: boolean;
	token?: number;
	icon?: string;
}
export interface LocationPressedEvent {
	id: number;
}

export interface ArcadeLogoInput {
	location?: number;
	showLocations?: boolean;
	hideBorder?: boolean;
	glowBorder?: boolean;
	glowLocations?: boolean;
	animation?: boolean;
	backgroundGIF?: string;
	oscillation?: boolean;
}

export interface ArcadeLogoOutput {
	locationPressed?: EventEmitter<LocationPressedEvent>;
}

/**
 * Component for displaying the arcade logo and interactive floor locations.
 * @example
 * <arcade-logo
 *   [location]="1"
 *   [showLocations]="true"
 *   [hideBorder]="false"
 *   [glowBorder]="true"
 *   [glowLocations]="false"
 *   [animation]="true"
 *   [backgroundGIF]="'logo-bg2.gif'"
 *   [oscillation]="false"
 *   (locationPressed)="onLocationPressed($event)"
 * ></arcade-logo>
 */
@Component({
	selector: "arcade-logo",
	standalone: false,
	templateUrl: "./arcade-logo.component.html",
	styleUrls: ["./arcade-logo.component.scss"],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class ArcadeLogoComponent implements AfterViewInit, OnChanges {
	/**
	 * Event emitted when a floor location is pressed.
	 * Emits the ID of the location that was pressed.
	 */
	@Output() locationPressed = new EventEmitter<number>();

	/**
	 * Event emitted when the mouse enters or leaves the logo.
	 * Emits a string of either "enter" or "leave".
	 */
	@Output() mouseAction = new EventEmitter<string>();

	/**
	 * The current selected floor location ID.
	 * Should be a number between 1 and 8.
	 * Defaults to 0 if no location is selected.
	 */
	@Input() location: number;

	/**
	 * Whether to show the interactive floor locations.
	 * Defaults to false.
	 */
	@Input() showLocations: boolean;

	/**
	 * Whether to hide the border around the logo.
	 * Defaults to false.
	 */
	@Input() hideBorder: boolean;

	/**
	 * Whether to make the border around the logo glow.
	 * Defaults to true.
	 */
	@Input() glowBorder: boolean;

	/**
	 * Whether to make the location inner glow.
	 * Defaults to false.
	 */
	@Input() glowLocations: boolean;

	/**
	 * Whether to animate the logo on load.
	 * Defaults to true.
	 */
	@Input() animation: boolean;

	/**
	 * The file name of the background GIF to use for the logo.
	 * Defaults to "logo-bg1.gif".
	 */
	@Input() backgroundGIF: string;

	/**
	 * Whether to oscillate the logo animation.
	 * Defaults to false.
	 */
	@Input() oscillation: boolean;

	/**
	 * Whether to display the display.
	 * Defaults to false.
	 */
	din = false;

	/**
	 * Page For Beeper in DIN Display
	 * Defaults to empty string.
	 */
	@Input() page: string;

	/**
	 * Array of Fish in Wallet
	 * Defaults to empty array.
	 */
	@Input() top8: any[];

	// public displayMessage: WritableSignal<string> = signal(this.page);

	/**
	 * Array of Locations
	 * Defaults to empty array.
	 */
	locations: LogoLocation[];

	/**
	 * Has the Exhibit UI Loaded
	 * Defaults to false.
	 */
	exhibitLoaded = false;
	/**
	 * Whether the logo has loaded.
	 * Defaults to false.
	 */
	loaded: boolean;

	/**
	 * Array of Beeps in Queue
	 * Defaults to empty array.
	 */
	private beepsQueue: string[] = [];
	/**
	 * You Got Beeps
	 * Defaults to empty array.
	 */
	isProcessingBeeps = false;
	/**
	 * The message on the pager display.
	 * Defaults to empty string.
	 */
	pagerMessage = "";

	constructor(private cdr: ChangeDetectorRef) {
		this.loaded = false;
		this.locations = [
			{ id: 1, active: false },
			{ id: 2, active: false },
			{ id: 3, active: false },
			{ id: 4, active: false },
			{ id: 5, active: false },
			{ id: 6, active: false },
			{ id: 7, active: false },
			{ id: 8, active: false },
		];
		this.backgroundGIF = "logo-bg1-200.gif";
		this.oscillation = false;
		this.glowLocations = false;

		setTimeout(() => {
			this.exhibitLoaded = true;
		}, 1600);
	}
	ngAfterViewInit() {
		if (this.showLocations && this.location > 0) {
			this.selectLocation(this.locations[this.location - 1]);
		}

		this.loaded = true;
	}

	ngOnChanges(changes: SimpleChanges) {
		let timeout = 100;
		if (this.animation && !this.loaded) {
			timeout = 2000;
		}

		if (changes.page) {
			this.beep(this.page);
		}

		setTimeout(() => {
			if (this.showLocations && this.location > 0) {
				this.selectLocation(this.locations[this.location - 1]);
			}
		}, timeout);

		if (changes.top8) {
			if (this.top8 && this.top8.length > 0) {
				if (this.top8.length > 8) {
					this.top8 = this.top8.slice(0, 8);
				}

				this.top8.forEach((token, index) => {
					this.locations[index].icon = getIPFSUrl(token.transparent_icon);
				});
			} else {
				this.resetLocations();
			}
		}
	}

	public beep(_beep: string) {
		if (!_beep || _beep === "") {
			return;
		}

		// Add the new message to the queue
		this.beepsQueue.push(_beep);

		// Process the queue if it's not already processing
		if (!this.isProcessingBeeps) {
			this.handleBeeps();
		}
	}

	handleBeeps() {
		// If there are no messages in the queue, stop processing
		if (this.beepsQueue.length === 0) {
			this.isProcessingBeeps = false;
			return;
		}

		// Set isProcessingQueue to true to prevent concurrent processing
		this.isProcessingBeeps = true;

		// Display the first message in the queue
		this.din = true;
		this.pagerMessage = this.beepsQueue[0];

		setTimeout(() => {
			// Remove the displayed message from the queue
			this.beepsQueue.shift();

			// Hide the message and continue processing the remaining messages
			this.din = false;
			this.handleBeeps();
		}, 2500);
	}

	@HostListener("mouseenter") onMouseEnter() {
		this.mouseAction.emit("enter");
	}

	@HostListener("mouseleave") onMouseLeave() {
		this.mouseAction.emit("leave");
	}

	// @HostListener("click") onClick() {}

	toggleLocations() {
		this.showLocations = !this.showLocations;
	}

	selectLocation(_location: LogoLocation) {
		this.locationPressed.emit(_location.id);
		for (const location of this.locations) {
			const element = document.getElementById("floor" + location.id) as HTMLElement;
			if (!element) {
				return;
			}

			if (element.classList.contains("active")) {
				element.classList.remove("active");
			}

			if (location.id === _location.id) {
				element.classList.add("active");
				this.locations[this.location - 1].active = true;
			} else {
				this.locations[this.location - 1].active = false;
			}
		}
	}

	resetLocations() {
		this.locations = [
			{ id: 1, active: false },
			{ id: 2, active: false },
			{ id: 3, active: false },
			{ id: 4, active: false },
			{ id: 5, active: false },
			{ id: 6, active: false },
			{ id: 7, active: false },
			{ id: 8, active: false },
		];
	}

	getDisplayMessage() {
		return this.pagerMessage;
	}

	pagerButton(id: number) {}
}

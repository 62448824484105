<div
	class="masthead-wrapper"
	id="masthead"
	[ngClass]="{
		'glow-border': glowBorder,
		'hide-border': hideBorder,
		'animate-floors': animation,
		'oscillate-logo': oscillation,
		'din-display': din,
		'beeping': isProcessingBeeps,
	}"
>
	<div class="logo-wrapper">
		<div class="logo-bg-animated">
			<img
				class="stars hue"
				[src]="'assets/images/logo/' + backgroundGIF"
				loading="eager"
				alt="Stars"
			/>
			<img
				class="stars right hue"
				[src]="'assets/images/logo/' + backgroundGIF"
				loading="eager"
				alt="Stars"
			/>
		</div>
		<div class="logo-bg">
			<img
				loading="eager"
				src="assets/images/logo/meta-logo-600.png"
				alt="Metaquarium Logo"
			/>
		</div>
	</div>
	<div class="display-wrapper" *ngIf="din">
		<arcade-display
			[title]="getDisplayMessage()"
			[fontSize]="26"
			[innerGlow]="true"
			[animated]="true"
			[dinSize]="'i'"
			[inverted]="true"
		/>
		<div class="pager-buttons" *ngIf="!showLocations">
			<button class="pager-button pb-1" (click)="pagerButton(1)"></button>
			<button class="pager-button pb-2" (click)="pagerButton(2)"></button>
			<button class="pager-button pb-3" (click)="pagerButton(3)"></button>
		</div>
	</div>

	<div
		class="exhibit-wrapper"
		id="exhibit"
		*ngIf="showLocations"
		[ngClass]="{ 'exhibit-loaded': exhibitLoaded }"
	>
		<div
			class="floor-location"
			id="floor{{ location.id }}"
			*ngFor="let location of locations; let i = index"
			[ngClass]="{
				'even-floor': location.id % 2 === 0,
				'glow-border-inner': glowLocations,
				'top8-icon': location.icon,
			}"
		>
			<div class="floor-number" [textContent]="location.id"></div>
			<div
				class="floor-icon"
				*ngIf="location.icon"
				[style.background-image]="'url(' + location.icon + ')'"
			></div>
			<div class="floor-water">
				<img
					class="floor-water-img"
					src="assets/images/logo/logo-water.svg"
					alt="Water"
				/>
				<img
					class="floor-water-img"
					src="assets/images/logo/logo-water.svg"
					alt="Water"
				/>
			</div>
		</div>
	</div>
</div>

<div class="knob-wrapper" #wrapper>
	<span class="detents-wrapper">
		@for (detent of detents; track detent) {
			<span
				class="detent"
				[style.width.px]="detentSize"
				[style.height.px]="detentSize"
				[style.backgroundColor]="detent.color"
				[style.boxShadow]="detent.color + ' 0px 0px 0px 3px'"
			>
				<!-- <arcade-indicator [size]="detentSize" [power]="true"></arcade-indicator> -->
			</span>
		}
	</span>
	<span class="dial-wrapper" #dial (mousedown)="onMouseDown()">
		<span class="dial-indicator" #indicator></span>
	</span>
	@if (label) {
		<div class="knob-label">{{ label }}</div>
	}
</div>

<div
	class="arcade-tile-wrapper"
	[style]="tileStyle"
	[ngClass]="{ transparent: transparent, 'optimized-tile': optimize }"
>
	<div
		class="arcade-tile"
		[style]="tileStyle"
		[ngClass]="{
			'hide-footer': hideFooter,
			'glow-border': glowBorder,
			'blur-content': blur
		}"
	>
		<div class="tile-content" #content [style]="contentStyle" draggable="false">
			<ng-content></ng-content>
		</div>
		<div class="tile-footer" *ngIf="!hideFooter" [style]="footerStyle">
			<span class="tile-footer-title" *ngIf="title">{{ title }}</span>
			<span class="tile-footer-actions">
				<button *ngIf="action" mat-icon-button>
					<mat-icon
						class="material-icons-outlined"
						*ngIf="action === 'mute'"
						(click)="actionPressed($event)"
						aria-hidden="false"
						aria-label="Mute"
						fontIcon="volume_off"
					></mat-icon>
					<mat-icon
						class="material-icons-outlined"
						*ngIf="action === 'mute_2'"
						(click)="actionPressed($event)"
						aria-hidden="false"
						aria-label="UnMute"
						fontIcon="volume_up"
					></mat-icon>
				</button>
			</span>
		</div>
	</div>
</div>

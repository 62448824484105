import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
export interface Tile {
	color: string;
	cols?: number;
	rows?: any;
	text?: string;
	buttons?: any[];
	textColor?: string;
	joystick?: boolean;
}

@Component({
	selector: "arcade-console",
	templateUrl: "./arcade-console.component.html",
	styleUrls: ["./arcade-console.component.scss"],
})
export class ArcadeConsoleComponent implements OnInit, AfterViewInit {
	@ViewChild("joystick") joystick!: ElementRef;
	@Output() pressed = new EventEmitter<boolean>();
	@Input() tiles!: any[];
	@Input() cols = 4;
	@Input() rowHeight = "170px";
	consoleTiles!: any[];

	initialConsoleTiles: Tile[] = [
		{ text: "Instructions: Insert Coins", cols: 64, rows: 3, color: "#800000" },
		{ cols: 64, rows: 1, color: "transparent" },
		{ cols: 2, rows: 12, color: "white" },
		{ cols: 1, rows: 12, color: "transparent" },
		{ cols: 2, rows: 12, color: "#800000" },
		{ cols: 1, rows: 12, color: "transparent" },
		{
			cols: 18,
			rows: 12,
			color: "white",
			textColor: "black",
			buttons: [
				{
					id: 1001,
					name: "A1",
					textColor: "black",
				},
				// {
				//   id: 1002,
				//   name: "A2",
				//   textColor: "black",
				// },
			],
		}, // A - Top Left Box
		{ cols: 1, rows: 12, color: "transparent" },
		{
			cols: 14,
			rows: 12,
			color: "#003F7F",
			joystick: true,
		}, // center
		{ cols: 1, rows: 12, color: "transparent" },
		{
			cols: 18,
			rows: 12,
			color: "#800000",
			buttons: [
				{
					id: 2000,
					name: "B1",
					textColor: "white",
				},
				// {
				//   id: 2001,
				//   name: "B2",
				//   textColor: "white",
				// },
			],
		}, // -- Top Right Box
		{ cols: 1, rows: 12, color: "transparent" },
		{ cols: 2, rows: 12, color: "#003F7F" },
		{ cols: 1, rows: 12, color: "transparent" },
		{ cols: 2, rows: 12, color: "white" },
		{ cols: 64, rows: 1, color: "transparent" }, //new line
	];

	asteroidsConsoleTiles: Tile[] = [
		{ cols: 64, rows: 8, color: "white" },
		{ text: "Instructions: Insert Coins", cols: 64, rows: 3, color: "#800000" },
		{ cols: 64, rows: 1, color: "transparent" },
		{ cols: 4, rows: 15, color: "white" },
		{ cols: 1, rows: 15, color: "transparent" },
		{ cols: 8, rows: 15, color: "#800000" },
		{ cols: 1, rows: 15, color: "transparent" },
		{ cols: 12, rows: 15, color: "white" }, // A - Top Left Box
		{ cols: 1, rows: 15, color: "transparent" },
		{ cols: 8, rows: 15, color: "#003F7F" }, // center
		{ cols: 1, rows: 15, color: "transparent" },
		{ cols: 12, rows: 15, color: "#800000" }, // -- Top Right Box
		{ cols: 1, rows: 15, color: "transparent" },
		{ cols: 8, rows: 15, color: "#003F7F" },
		{ cols: 1, rows: 15, color: "transparent" },
		{ cols: 4, rows: 15, color: "white" },
		{ cols: 64, rows: 1, color: "transparent" }, //new line
		{ cols: 2, rows: 8, color: "white" },
		{ cols: 2, rows: 8, color: "black" },
		{ cols: 2, rows: 8, color: "#003F7F" },
		{ cols: 2, rows: 8, color: "#880000" },
		{ cols: 2, rows: 8, color: "white" },
		{ cols: 2, rows: 8, color: "#003F7F" },
		{ cols: 2, rows: 8, color: "black" },
		{ cols: 12, rows: 16, color: "#003F7F" }, //blue left square
		{ cols: 1, rows: 16, color: "transparent" },
		{ cols: 8, rows: 8, color: "#880000" }, // red middle square
		{ cols: 1, rows: 16, color: "transparent" },
		{ cols: 12, rows: 16, color: "#003F7F" }, //blue right square
		// { cols: 1, rows: 16, color: "transparent" },
		{ cols: 2, rows: 8, color: "#003F7F" },
		{ cols: 2, rows: 8, color: "white" },
		{ cols: 2, rows: 8, color: "#003F7F" },
		{ cols: 2, rows: 8, color: "black" },
		{ cols: 2, rows: 8, color: "#003F7F" },
		{ cols: 2, rows: 8, color: "#880000" },
		{ cols: 2, rows: 8, color: "white" },
		{ cols: 2, rows: 8, color: "#003F7F" }, //end of line top
		{ cols: 2, rows: 8, color: "black" },
		{ cols: 2, rows: 8, color: "#003F7F" },
		{ cols: 2, rows: 8, color: "#880000" },
		{ cols: 2, rows: 8, color: "white" },
		{ cols: 2, rows: 8, color: "#003F7F" },
		{ cols: 2, rows: 8, color: "#880000" },
		{ cols: 2, rows: 8, color: "white" },
		{ cols: 8, rows: 8, color: "#003F7F" }, // blue middle square
		{ cols: 2, rows: 8, color: "white" },
		{ cols: 2, rows: 8, color: "#003F7F" },
		{ cols: 2, rows: 8, color: "black" },
		{ cols: 2, rows: 8, color: "#003F7F" },
		{ cols: 2, rows: 8, color: "#880000" },
		{ cols: 2, rows: 8, color: "#003F7F" },
		{ cols: 2, rows: 8, color: "#880000" },
		{ cols: 2, rows: 8, color: "white" }, // end of line 2
	];

	constructor() {
		this.consoleTiles = this.initialConsoleTiles;
	}
	onResize(event) {
		this.cols = event.target.innerWidth <= 400 ? 2 : 4;
	}

	ngOnInit(): void {
		this.cols = window.innerWidth <= 400 ? 2 : 4;
		if (this.tiles) {
			this.consoleTiles = this.tiles;
		}
	}

	ngAfterViewInit() {}

	event(_id: any) {
		this.pressed.emit(_id);
	}

	getStick() {
		const options: any = {
			// zone: this.joystick.nativeElement,
			color: "green",
			size: 100,
			dynamicPage: true,
			shape: "square",
			position: { left: "0", top: "0" },
			mode: "semi",
		};

		// const nipplejs = require("nipplejs");

		// const manager = nipplejs.create({
		// 	color: "white",
		// 	zone: this.joystick.nativeElement,
		// 	size: 100,
		// 	dynamicPage: true,
		// 	mode: "static",
		// 	position: { left: "50%", top: "50%" },
		// });

		// manager.on("start", function (evt, data) {});

		// const manager = nipplejs.create(options);

		return "shawn";
	}
}

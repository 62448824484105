import { Component, HostBinding, Input, OnChanges, OnInit } from "@angular/core";
import { TooltipPosition } from "@angular/material/tooltip";

@Component({
	selector: "arcade-indicator",
	templateUrl: "./arcade-indicator.component.html",
	styleUrls: ["./arcade-indicator.component.scss"],
})
export class ArcadeIndicatorComponent implements OnInit, OnChanges {
	/**
	 * Light Height / Width in Pixels.
	 */
	@Input() size = 35;

	/**
	 * Light in a disabled status.
	 */
	@Input() power = true;

	/**
	 * Light Color.
	 */
	@Input() color?: string = "#ff0000";

	/**
	 * Light Shadow Color. If not set, defaults to the light color.
	 */
	@Input() shadowColor?: string = this.color;

	/**
	 * Light Shadow Spread.
	 */
	@Input() shadowSpread?: number = this.size / 10;

	/**
	 * Light Shadow Blur.
	 */
	@Input() shadowBlur?: number = this.size * 0.5;

	/**
	 * Label for indivator
	 */
	@Input() label!: string;

	/**
	 * Position of tooltip
	 */
	@Input() tooltipPosition: TooltipPosition = "above";

	/**
	 * Error state
	 */
	@Input() error = false;

	/**
	 * Blinking state
	 */
	@Input() blink: boolean;

	/**
	 * Gets the color to use for the blink animation.
	 */
	@HostBinding("style.--blink-color") get hostBlinkColor() {
		return this.color;
	}

	lightStyle: any = {
		backgroundColor: this.color,
		width: this.size + "px",
		height: this.size + "px",
		boxShadow: "inset 0px 0px 10px 5px " + this.shadowColor,
		display: "inline-block",
	};

	lightWrapperStyle = {
		// minHeight: this.size / 2 + "px",
		width: this.size + "px",
		margin: this.size / 8 + "px",
	};

	/**
	 * Label for tooltip on hover. Empty for none.
	 */
	@Input() tooltip = "";

	constructor() {
		if (!this.size) {
			this.size = 20;
		}
	}

	ngOnInit() {}

	ngOnChanges() {
		this.lightStyle = {
			...this.lightStyle,
			height: this.size + "px",
			width: this.size + "px",
			backgroundColor: this.color,
			boxShadow: "0px 0px " + this.shadowBlur + "px " + this.shadowSpread + "px " + this.shadowColor,
		};
		if (!this.power) {
			this.lightStyle = {
				...this.lightStyle,
				boxShadow: "unset",
			};
		}

		// if (this.error) {
		// 	this.blink = true;
		// } else {
		// 	this.blink = false;
		// }
	}
}

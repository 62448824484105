<span class="indicator-wrapper" [style]="lightWrapperStyle">
	<div
		class="indicator-light"
		[style]="lightStyle"
		[matTooltipPosition]="tooltipPosition"
		[class.blinking]="blink"
		matTooltip="{{ tooltip }}"
	></div>
	<div class="indicator-label" *ngIf="label" [style.fontSize]="size">
		{{ label }}
	</div>
</span>

<ng-container *ngIf="lines > 0; else notVisible">
	<div
		class="arcade-display-wrapper"
		[@collapse]="'open'"
		[class.innerGlow]="innerGlow"
		[class.animated]="animated"
		[class.din-i]="dinSize === 'i'"
		[class.din-ii]="dinSize === 'ii'"
		[class.din-iii]="dinSize === 'iii'"
		[style]="wrapperStyle"
		[class.inverted]="inverted"
	>
		<!-- <video class="video-player" id="videoPlayer" #videoPlayer [src]="'../../../assets/video/dim_vx_80.mp4'"></video> -->
		<div class="arcade-display-title" [style]="titleStyle">
			<span
				class="arcade-display-title-text"
				#displayText
				[style.fontSize]="fontSize"
				>{{ title }}</span
			>
		</div>
		<div class="arcade-display-right" *ngIf="contentRight">
			<img
				class="arcade-display-image"
				*ngIf="image"
				src="assets/images/viz.png"
				alt="Visualizer"
			/>
		</div>
	</div>
</ng-container>
<ng-template #notVisible>
	<div [@collapse]="'closed'"></div>
</ng-template>

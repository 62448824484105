import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
	selector: "app-arcade-loading",
	templateUrl: "./arcade-loading.component.html",
	styleUrls: ["./arcade-loading.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArcadeLoadingComponent {
	progress = 0;
	interval: any;

	constructor() {
		this.interval = setInterval(() => {
			this.progress += 10;
			if (this.progress >= 100) {
				this.progress = 0;
			}
		}, 1000);
	}

	ngOnDestroy() {
		clearInterval(this.interval);
	}
}

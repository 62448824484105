import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// Material needed for App. Move these elsewhere
import { MatButtonModule } from "@angular/material/button";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from "@angular/material/snack-bar";

import { SupabaseService } from "@app/_shared/supabase.service";
import { HashEllipsisPipe } from "@app/pipes/hash-ellipsis.pipe";
import { PipesModule } from "@app/pipes/pipes.module";

@NgModule({
	declarations: [],
	imports: [CommonModule, BrowserAnimationsModule, MatSnackBarModule, PipesModule, HttpClientModule, MatGridListModule, MatButtonModule, MatIconModule],
	exports: [CommonModule, BrowserAnimationsModule, MatSnackBarModule, PipesModule, HttpClientModule, MatGridListModule, MatButtonModule, MatIconModule],
	providers: [
		HashEllipsisPipe,
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: { duration: 5000, verticalPosition: "top", horizontalPosition: "center", panelClass: "arcade-snackbar" },
		},
		SupabaseService,
	],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error("CoreModule is already loaded. Import it in the AppModule only");
		}
	}
}
